import React from 'react'

const App = () => {
    
    console.log("hi")
    
    window.location.replace("https://renter.turbotenant.com/auth/login");

    return (
        <>
        </>
    )
}

export default App
